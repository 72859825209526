<template>
	<div class="trade">
		<section class="section bg-gray">
			<div class="container">
				<h1 class="section-title">Get in Touch, Start OTC Trading</h1>
				<p class="section-sub-title">BitFree Capital prides ourselves with our seamless trading experience and extensive customer support, feel free to get in touch to learn more about our services</p>
				<div class="reg-act">
					<div class="sel-act">
						<div class="flex flex-between">
							<div class="act">
								<div class="flex flex-col h100">
									<div class="flex-fill">
										<div>
											<div class="avatar">
												<img src="/imgs/icons8-user-skin-type-7-48.png">
											</div>
										</div>
										<div class="act-title">For Individuals</div>
										<div class="act-desc">
											Please have all documents ready before starting the process.
										</div>
										<div class="act-doc">
											<p>1. Copy of Passport</p>
											<p>2. Bank statement (within the latest 3 months, with your name, bank account number bank name and address)</p>
											<p>3. Phone bill (within the last 3 months, with your name and the phone number used to communicate with QCP) If phone bill is not in your name, please fill in the Letter of Declaration (Phone Bill)</p>
										</div>
									</div>
									<div class="flex-auto sat">
										<button class="act-btn" @click="start(0)">Get Started</button>
									</div>
								</div>
							</div>
							<div class="act">
								<div class="flex flex-col h100">
									<div class="flex-fill">
										<div>
											<div class="avatar">
												<img src="/imgs/icons8-library-48.png">
											</div>
										</div>
										<div class="act-title">For Institutions</div>
										<div class="act-desc">
											Please have all documents ready before starting the process.
										</div>
										<div class="act-doc">
											<p>1. Certificate of Incorporation</p>
											<p>2. Memorandum and Articles of Association</p>
											<p>3. Directors' Register</p>
											<p>4. Shareholders' Register — (in cases where there are corporate shareholders with >20% shareholding, we will require items 1-5 again)</p>
											<p>5. Corporate bank account statement (stating company name, bank name and bank account number)</p>
											<p>6. Passport, bank statement for:</p>
											<p>(a) Directors</p>
											<p>(b) Individual shareholders / Ultimate Beneficial Owners with ≥20% holding</p>
											<p>7. Passport and phone bill for Authorised Traders</p>
											<p>8. Master Trading Agreement (sent via email)</p>
										</div>
									</div>
									<div class="flex-auto sat">
										<button class="act-btn" @click="start(1)">Get Started</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	export default{
		name:'Trade',
		methods:{
			start(type){
				this.$router.push({name: 'Register', params: {type}})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.trade{
		padding-top: 64px;
	}
	.reg-act{
		margin-top: 30px;
	}
	.sel-act{
		width: 800px;
		margin: 0 auto;
	}
	.act{
		width: 380px;
		height: 700px;
		background-color: #fff;
		box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.06);
		border-radius: 4px;
		padding: 40px 30px;
		box-sizing: border-box;
	}
	.sat{
		position: relative;
		text-align: center;
	}
	.act-btn{
		width: 100%;
		padding: 10px 0;
		background-color: #f7bd05;
		color: #212833;
		font-size: 15px;
		border-radius: 4px;
		border: none;
		cursor: pointer;
	}
	.avatar{
		width: 90px;
		height: 90px;
		margin: 0 auto;
		border-radius: 50%;
		padding: 5px;
		display: flex;
		-webkit-flex: flex;
		-ms-flex: flex;
		align-items: center;
		-webkit-align-items: center;
	    -ms-flex-align: center;
	    -webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.06);
		overflow: hidden;
		img{
			width: 80px;
			height: 80px;
		}
	}
	.act-title{
		font-size: 18px;
		font-weight: 600;
		color: #212833;
		padding: 20px 0;
		text-align: center;
	}
	.act-desc{
		font-size: 13px;
		font-weight: 600;
		margin-bottom: 20px;
		color: #212833;
	}
	.act-doc p{
		margin-bottom: 10px;
	}
</style>